import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useListStudiosQuery } from '../../data/api/studioApi';
import Pagination from '../../Components/Pagination';
import Loader from '../../Components/Loader';
import { useQuery } from '../../utils/customHooks';
import TnTable from '../../Components/Table/TnTable';
import { BIZ_DEV_EMAIL_LIST } from '../../constants/global';
import moment from 'moment';
import UpdateStudioModal from './UpdateStudioModal';
import TnTableFiltersContainer from '../../Components/Table/TnTableFiltersContainer/TnTableFiltersContainer';
import UserModal from './studioListModals/UserModal';
import AppsModal from './studioListModals/AppsModal';
import { setStudioSelected } from '../../data/slices/user';

const StudioList = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const initialSearch = query.get('search') || '';
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { data, isFetching: isLoading } = useListStudiosQuery();
  const [showAppsModal, setShowAppsModal] = useState(null);
  const [showUsersModal, setShowUsersModal] = useState(null);

  const [filters, setFilters] = useState([
    { name: 'search', type: 'search', value: initialSearch, placeholder: 'Search Users...' },
    {
      name: 'bizdevEmail',
      type: 'select',
      label: 'BizDev Email',
      options: BIZ_DEV_EMAIL_LIST.map((item) => ({
        label: item,
        value: item,
      })),
      value: '',
    },
  ]);

  const handleShowModal = (data, type) => {
    if (type === 'app') {
      dispatch(setStudioSelected(data));
      setShowAppsModal(true);
    } else setShowUsersModal(data);
  };

  const handleClose = () => {
    setShowAppsModal(null);
    setShowUsersModal(null);
  };

  useEffect(() => {
    if (data) {
      let filteredData = data.filter((user) => {
        let valid = true;
        filters.forEach((filter) => {
          if (filter.value) {
            switch (filter.name) {
              case 'search':
                const searchValue = filter.value.toLowerCase();
                valid =
                  valid &&
                  (user.email.toLowerCase().includes(searchValue) ||
                    user.name.toLowerCase().includes(searchValue) ||
                    user.bizdevEmail.toLowerCase().includes(searchValue));
                break;
              case 'bizdevEmail':
                valid = valid && filter.value === user.bizdevEmail;
                break;
              default:
                break;
            }
          }
        });
        return valid;
      });
      const transformedData = filteredData.map((user) => ({
        ...user,
        actions: <UpdateStudioModal data={user} />,
        insertedAt: moment(user.insertedAt).format('ll'),
        userCount: {
          value: user.userCount,
          label: (
            <div className="d-flex align-items-center">
              <div
                className={`text-white fs-7 bg-secondary py-1 px-2 m-1 rounded ${user.userCount && 'cursor-pointer'}`}
                onClick={() => user.userCount && handleShowModal(user, 'user')}
              >
                {user.userCount}
              </div>
            </div>
          ),
        },
        appCount: {
          value: user.appCount,
          label: (
            <div className="d-flex align-items-center">
              <div
                className={`text-white fs-7 bg-secondary py-1 px-2 m-1 rounded ${user.appCount && 'cursor-pointer'}`}
                onClick={() => user.appCount && handleShowModal(user, 'app')}
              >
                {user.appCount}
              </div>
            </div>
          ),
        },
      }));

      setFilteredUsers(transformedData);
    }
  }, [data, filters]);

  const header = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'email', label: 'E-mail', sortable: true },
    { key: 'bizdevEmail', label: 'Contact Email', sortable: true },
    { key: 'userCount', label: 'Users', sortable: true },
    { key: 'appCount', label: 'Apps', sortable: true },
    { key: 'studioId', label: 'Studio Id', sortable: true },
    { key: 'insertedAt', label: 'Inserted At', sortable: true },
    { key: 'actions', label: '' },
  ];

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 d-flex flex-lg-row flex-column gap-1 align-items-lg-center">
          {isLoading ? null : (
            <div className="d-flex gap-1">
              <TnTableFiltersContainer
                filters={filters}
                setFilters={(s) => {
                  setFilters(s);
                  setPage(1);
                }}
              />
            </div>
          )}
          <div className="ms-auto d-flex align-items-center">
            <Pagination
              total={filteredUsers?.length || 0}
              perPage={perPage}
              page={page}
              pushHistory={false}
              onChange={(currentPage) => {
                setPage(currentPage);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : filteredUsers?.length ? (
            <div className="table-responsive">
              <TnTable data={filteredUsers} page={page} perPage={perPage} header={header} />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <span className="text-dark me-2">No results found.</span>
            </div>
          )}
        </div>
      </div>

      {showUsersModal && <UserModal data={showUsersModal} onHide={handleClose} />}
      {showAppsModal && <AppsModal onHide={handleClose} />}
    </>
  );
};

export default StudioList;
