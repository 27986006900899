import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { confirmAlert } from '../../../Components/ConfirmModal';
import {
  studioApi,
  useCreateGameAnalyticsGameMutation,
  usePublishDraftGameMutation,
  useUpdateStudioMutation,
} from '../../../data/api/studioApi';
import Loader from '../../../Components/Loader';
import { Restricted } from '../../../Components/permissions/UserPermissions';
import GameAnalyticsStudioID from '../../UserManagement/CreateStudio/GameAnalyticsStudioID';

const PublishDraftGame = ({ id, showCount, onSuccess }) => {
  const { data: games } = studioApi.endpoints.getDraftGames.useQueryState();
  const { data: studioList } = studioApi.endpoints.listStudios.useQueryState();
  const [showGaModal, setShowGaModal] = useState(false);
  const [gaStudioId, setGaStudioId] = useState('');

  const [updateStudio, { isLoading: isUpdatingStudio }] = useUpdateStudioMutation();

  let game = games?.find((el) => el.id === Number(id)) || {};
  const studio = studioList?.find((s) => s.studioId === game?.studioId) || {};
  const initialGaStudioId = studio?.gaStudioId;

  const infos = [game?.appName, game?.bundleId, game?.appId, game?.bizdevEmail, game?.category, game?.tags];
  let nbInfos = 0;
  infos.forEach((i) => {
    if (i && i !== '') {
      nbInfos += 1;
    }
  });

  let sdkArray = game?.todoList?.split(',') || [];
  let nbSdk = sdkArray.length || 0;

  let active = game?.id && nbInfos === 6 && nbSdk === 4;
  const [publish, { isLoading }] = usePublishDraftGameMutation();
  const [createGa, { isLoading: isLoadingGa }] = useCreateGameAnalyticsGameMutation();
  const handlePublish = (e) => {
    e.stopPropagation();

    if (!initialGaStudioId) {
      setShowGaModal(true);
    } else {
      confirmPublish();
    }
  };
  const handleGameGa = (game) => {
    return createGa(game)
      .unwrap()
      .then((response) => {
        confirmAlert({
          variant: 'success',
          title: 'Successfully Published and created Game Analytics for the game ' + game.appName,
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Game published but GameAnalytics_ID not created!',
          desc: err?.data?.details.map((e) => e.detail).join(', ') || undefined,
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const confirmPublish = () => {
    confirmAlert({
      variant: 'warning',
      title: 'Are you sure you want to Publish game "' + game.appName + '"?',
      desc: 'Published game can not be removed!',
      confirmText: 'Publish',
    })
      .then(() => {
        publish(game?.id)
          .unwrap()
          .then(async (response) => {
            await handleGameGa(response);
            if (onSuccess) onSuccess(response);
          })
          .catch((err) => {
            confirmAlert({
              variant: 'danger',
              title: err?.data?.message || 'Something went wrong',
              confirmBtn: false,
              cancelText: 'Ok',
            }).catch(() => {});
          });
      })
      .catch(() => {});
  };

  const handleGaModalSubmit = () => {
    updateStudio({
      id: game?.studioId,
      ga_studio_id: gaStudioId,
    })
      .unwrap()
      .then(() => {
        setShowGaModal(false);
        confirmPublish();
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to update Game Analytics Studio ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  return (
    <Restricted permission="draftGame.publish">
      <Button className="text-nowrap" variant={'success'} disabled={!active} onClick={(e) => handlePublish(e)}>
        {(isLoading || isUpdatingStudio || isLoadingGa) && <Loader parentStyle="me-2" size={20} color={'#3F96C7'} />}
        Publish {showCount ? nbInfos + '/7' : null}
      </Button>

      <Modal show={showGaModal} onHide={() => setShowGaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Game Analytics Studio ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUpdatingStudio ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              <p>
                You need to set up a Game Analytics Studio for this studio. Please select an existing one or create a
                new one.
              </p>
              <GameAnalyticsStudioID
                value={gaStudioId}
                onChange={(newID) => setGaStudioId(newID)}
                studioName={studio?.name || ''}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowGaModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleGaModalSubmit} disabled={!gaStudioId}>
            Save and Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </Restricted>
  );
};

export default PublishDraftGame;
