import React, { useEffect, useState } from 'react';
import MetricsTable from '../../../../../Components/JsonMetrics/MetricsTable';
import { getDataCell } from '../../../../../utils/jsonMetricsUtils';

function PlayTimeMetrics({ apiData }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!apiData?.summary?.length) return;

    const newData = {
      sessions: {
        header: [
          { value: 'Cohort' },
          { value: 'Avg playtime' },
          { value: 'Avg sessions' },
          { value: 'Avg sessions length' },
        ],
        data: [],
        title: 'Summary',
      },
      playtimeD: {
        header: [
          { value: 'Cohort' },
          { value: 'Day 0' },
          { value: 'Day 1' },
          { value: 'Day 2' },
          { value: 'Day 3' },
          { value: 'Day 4' },
          { value: 'Day 7' },
          { value: 'Day 14' },
          { value: 'Day 30' },
        ],
        data: [],
        title: 'Playtime',
      },
      sessionsD: {
        header: [
          { value: 'Cohort' },
          { value: 'Day 0' },
          { value: 'Day 1' },
          { value: 'Day 2' },
          { value: 'Day 3' },
          { value: 'Day 4' },
          { value: 'Day 7' },
          { value: 'Day 14' },
          { value: 'Day 30' },
        ],
        data: [],
        title: 'Sessions',
      },
    };

    apiData.summary.forEach((el) => {
      const isBaseCohort = el.user_cohort?.toLowerCase() === 'basecohort';

      newData.sessions.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.playtime, el.meta, 'playtime', isBaseCohort, 'decimal'),
        getDataCell(el.ga_sessions, el.meta, 'ga_sessions', isBaseCohort, 'decimal'),
        getDataCell(el.session_length, el.meta, 'session_length', isBaseCohort, 'decimal'),
      ]);

      newData.sessionsD.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.num_sessions_d0, el.meta, 'num_sessions_d0', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d1, el.meta, 'num_sessions_d1', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d2, el.meta, 'num_sessions_d2', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d3, el.meta, 'num_sessions_d3', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d4, el.meta, 'num_sessions_d4', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d7, el.meta, 'num_sessions_d7', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d14, el.meta, 'num_sessions_d14', isBaseCohort, 'decimal'),
        getDataCell(el.num_sessions_d30, el.meta, 'num_sessions_d30', isBaseCohort, 'decimal'),
      ]);

      newData.playtimeD.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.playtime_d0, el.meta, 'playtime_d0', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d1, el.meta, 'playtime_d1', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d2, el.meta, 'playtime_d2', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d3, el.meta, 'playtime_d3', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d4, el.meta, 'playtime_d4', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d7, el.meta, 'playtime_d7', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d14, el.meta, 'playtime_d14', isBaseCohort, 'decimal'),
        getDataCell(el.playtime_d30, el.meta, 'playtime_d30', isBaseCohort, 'decimal'),
      ]);
    });

    setData(newData);
  }, [apiData]);

  return (
    <>
      {!!data?.sessions.data?.length && <MetricsTable {...data.sessions} downloadCsv csvDiffFromBase />}
      {!!data?.playtimeD.data?.length && <MetricsTable {...data.playtimeD} downloadCsv csvDiffFromBase />}
      {!!data?.sessionsD.data?.length && <MetricsTable {...data.sessionsD} downloadCsv csvDiffFromBase />}
    </>
  );
}

export default PlayTimeMetrics;
