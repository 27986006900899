import { useGetDraftGamesQuery, useListStudiosQuery } from '../../../../data/api/studioApi';
import Loader from '../../../../Components/Loader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GameInfoWidget from './GameInfoWidget';
import Videos from './Videos';
import { snakeCaseToCamelObj } from '../../../../utils/converters';
import EmailSendModal from '../../../../Modules/EmailSendModal';
import VideoTips from './VideoTips';
import { Restricted, useUserPermission } from '../../../../Components/permissions/UserPermissions';

const DraftGameInfo = () => {
  const selectStudioPermission = useUserPermission('select.studio');
  let { id } = useParams();
  const { data: draftGames, isFetching: isLoadingDarftGames } = useGetDraftGamesQuery();
  const { data: studioList } = useListStudiosQuery(undefined, {
    skip: !selectStudioPermission,
  });

  const [data, setData] = useState(null);
  const [studioData, setStudioData] = useState(null);

  useEffect(() => {
    if (draftGames) {
      let game = draftGames.find((el) => Number(el.id) === Number(id));
      setData(snakeCaseToCamelObj(game || {}));
    }
  }, [draftGames]);

  useEffect(() => {
    if (studioList && data) {
      let studio = studioList.find((el) => el?.studioId === data?.studioId);
      setStudioData(studio);
    }
  }, [data, studioList]);

  return isLoadingDarftGames ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : (
    !!data && (
      <>
        <div className="row g-4">
          <div className="col-md-6 col-lg-4 col-12">
            <GameInfoWidget data={{ ...data, os: data.bundleId === data.appId ? 'android' : 'ios' }} />
            <Restricted permission="send.email">
              <div className="mt-2">
                <EmailSendModal to={studioData?.email} />
              </div>
            </Restricted>
          </div>
          <div className="col-md-6  col-lg-8 col-12">
            <div className="bg-white rounded px-4 py-3">
              <h6 className="fs-7">Game Videos</h6>
              <Videos infosGame={data} />
            </div>
          </div>
        </div>
        <div>
          <VideoTips defaultlyOpen />
        </div>
      </>
    )
  );
};

export default DraftGameInfo;
