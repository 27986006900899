import { NavLink, Switch, useLocation } from 'react-router-dom';
import Icons from '../Components/DevPage/Icons';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import GuardedRoute from '../Modules/GuardedRoute';
import ConfirmAlertDemo from '../Components/DevPage/ConfirmAlertDemo';
import SearchableDropdownDemo from '../Components/DevPage/SearchableDropdownDemo';
import GoogleDriveModalDemo from '../Components/DevPage/GoogleDriveModalDemo';
import SendEmailDemo from '../Components/DevPage/SendEmailDemo';
import MetricsTableDemo from '../Components/DevPage/MetricsTableDemo';
import EmptyLayout from '../Layouts/EmptyLayout';
import UserPermission from '../Components/DevPage/UserPermissionDemo';
import SearchInputDemo from '../Components/DevPage/SearchInputDemo';

const DevPage = () => {
  const location = useLocation();

  return (
    <Container>
      <Row>
        <Col xs={3}>
          <Nav defaultActiveKey={location.pathname} className="flex-column">
            <Nav.Link as={NavLink} to="/dev">
              Icons
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/confirm">
              Confirm Alert
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/searchableDropdown">
              SearchableDropdown
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/drive-modal">
              Google Drive Modal
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/emailSend">
              Email send
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/matrixTable">
              Metrics Table
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/userPermission">
              User Permission
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev/searchInput">
              search input
            </Nav.Link>
          </Nav>
        </Col>
        <Col xa={9}>
          <Switch>
            <GuardedRoute layout={EmptyLayout} path={'/dev/drive-modal'} component={GoogleDriveModalDemo} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/searchInput'} component={SearchInputDemo} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/userPermission'} component={UserPermission} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/confirm'} component={ConfirmAlertDemo} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/searchableDropdown'} component={SearchableDropdownDemo} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/emailSend'} component={SendEmailDemo} />
            <GuardedRoute layout={EmptyLayout} path={'/dev/matrixTable'} component={MetricsTableDemo} />
            <GuardedRoute layout={EmptyLayout} component={Icons} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default DevPage;
