import React, { useEffect, useState } from 'react';
import MetricsTable from '../../../../../Components/JsonMetrics/MetricsTable';
import { getDataCell } from '../../../../../utils/jsonMetricsUtils';

function IapMetrics({ apiData }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!apiData?.summary?.length) return;

    const newData = {
      ltvIapPivot: {
        header: [
          { value: 'Cohort' },
          { value: 'ltv 0' },
          { value: 'ltv 1' },
          { value: 'ltv 2' },
          { value: 'ltv 3' },
          { value: 'ltv 4' },
          { value: 'ltv 5' },
          { value: 'ltv 6' },
          { value: 'ltv 7' },
          { value: 'ltv 14' },
          { value: 'ltv 30' },
        ],
        data: [],
        title: 'IAP Revenue',
      },
      iapPurchases: {
        header: [
          { value: 'Cohort' },
          { value: 'ltv 0' },
          { value: 'ltv 1' },
          { value: 'ltv 2' },
          { value: 'ltv 3' },
          { value: 'ltv 4' },
          { value: 'ltv 5' },
          { value: 'ltv 6' },
          { value: 'ltv 7' },
          { value: 'ltv 14' },
          { value: 'ltv 30' },
        ],
        data: [],
        title: 'IAP Purchases',
      },
      iapPayingUsers: {
        header: [
          { value: 'Cohort' },
          { value: 'ltv 0' },
          { value: 'ltv 1' },
          { value: 'ltv 2' },
          { value: 'ltv 3' },
          { value: 'ltv 4' },
          { value: 'ltv 5' },
          { value: 'ltv 6' },
          { value: 'ltv 7' },
          { value: 'ltv 14' },
          { value: 'ltv 30' },
        ],
        data: [],
        title: 'IAP Paying Users',
      },
      iapPurchasesPerUsers: {
        header: [
          { value: 'Cohort' },
          { value: 'ltv 0' },
          { value: 'ltv 1' },
          { value: 'ltv 2' },
          { value: 'ltv 3' },
          { value: 'ltv 4' },
          { value: 'ltv 5' },
          { value: 'ltv 6' },
          { value: 'ltv 7' },
          { value: 'ltv 14' },
          { value: 'ltv 30' },
        ],
        data: [],
        title: 'IAP Purchases Per Paying User',
      },
    };

    apiData.summary.forEach((el) => {
      const isBaseCohort = el.user_cohort?.toLowerCase() === 'basecohort';

      newData.ltvIapPivot.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.iap0, el.meta, 'iap0', isBaseCohort, 'decimal'),
        getDataCell(el.iap1, el.meta, 'iap1', isBaseCohort, 'decimal'),
        getDataCell(el.iap2, el.meta, 'iap2', isBaseCohort, 'decimal'),
        getDataCell(el.iap3, el.meta, 'iap3', isBaseCohort, 'decimal'),
        getDataCell(el.iap4, el.meta, 'iap4', isBaseCohort, 'decimal'),
        getDataCell(el.iap5, el.meta, 'iap5', isBaseCohort, 'decimal'),
        getDataCell(el.iap6, el.meta, 'iap6', isBaseCohort, 'decimal'),
        getDataCell(el.iap7, el.meta, 'iap7', isBaseCohort, 'decimal'),
        getDataCell(el.iap14, el.meta, 'iap14', isBaseCohort, 'decimal'),
        getDataCell(el.iap30, el.meta, 'iap30', isBaseCohort, 'decimal'),
      ]);

      newData.iapPurchases.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.iap_purchases_0, el.meta, 'iap_purchases_0', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_1, el.meta, 'iap_purchases_1', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_2, el.meta, 'iap_purchases_2', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_3, el.meta, 'iap_purchases_3', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_4, el.meta, 'iap_purchases_4', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_5, el.meta, 'iap_purchases_5', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_6, el.meta, 'iap_purchases_6', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_7, el.meta, 'iap_purchases_7', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_14, el.meta, 'iap_purchases_14', isBaseCohort, 'decimal'),
        getDataCell(el.iap_purchases_30, el.meta, 'iap_purchases_30', isBaseCohort, 'decimal'),
      ]);

      newData.iapPayingUsers.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(el.iap_paying_users_0, el.meta, 'iap_paying_users_0', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_1, el.meta, 'iap_paying_users_1', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_2, el.meta, 'iap_paying_users_2', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_3, el.meta, 'iap_paying_users_3', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_4, el.meta, 'iap_paying_users_4', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_5, el.meta, 'iap_paying_users_5', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_6, el.meta, 'iap_paying_users_6', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_7, el.meta, 'iap_paying_users_7', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_14, el.meta, 'iap_paying_users_14', isBaseCohort, 'decimal'),
        getDataCell(el.iap_paying_users_30, el.meta, 'iap_paying_users_30', isBaseCohort, 'decimal'),
      ]);

      newData.iapPurchasesPerUsers.data.push([
        { value: el.user_cohort, className: isBaseCohort ? 'bg-light-400' : '' },
        getDataCell(
          el.iap_purchases_per_paying_user_0,
          el.meta,
          'iap_purchases_per_paying_user_0',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_1,
          el.meta,
          'iap_purchases_per_paying_user_1',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_2,
          el.meta,
          'iap_purchases_per_paying_user_2',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_3,
          el.meta,
          'iap_purchases_per_paying_user_3',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_4,
          el.meta,
          'iap_purchases_per_paying_user_4',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_5,
          el.meta,
          'iap_purchases_per_paying_user_5',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_6,
          el.meta,
          'iap_purchases_per_paying_user_6',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_7,
          el.meta,
          'iap_purchases_per_paying_user_7',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_14,
          el.meta,
          'iap_purchases_per_paying_user_14',
          isBaseCohort,
          'decimal',
        ),
        getDataCell(
          el.iap_purchases_per_paying_user_30,
          el.meta,
          'iap_purchases_per_paying_user_30',
          isBaseCohort,
          'decimal',
        ),
      ]);
    });

    setData(newData);
  }, [apiData]);

  return (
    <>
      {!!data?.ltvIapPivot.data?.length && (
        <MetricsTable
          data={data.ltvIapPivot.data}
          title={data.ltvIapPivot.title}
          header={data.ltvIapPivot.header}
          downloadCsv
        />
      )}
      {!!data?.iapPurchases.data?.length && (
        <MetricsTable
          data={data.iapPurchases.data}
          title={data.iapPurchases.title}
          header={data.iapPurchases.header}
          downloadCsv
        />
      )}
      {!!data?.iapPayingUsers.data?.length && (
        <MetricsTable
          data={data.iapPayingUsers.data}
          title={data.iapPayingUsers.title}
          header={data.iapPayingUsers.header}
          downloadCsv
        />
      )}
      {!!data?.iapPurchasesPerUsers.data?.length && (
        <MetricsTable
          data={data.iapPurchasesPerUsers.data}
          title={data.iapPurchasesPerUsers.title}
          header={data.iapPurchasesPerUsers.header}
          downloadCsv
        />
      )}
    </>
  );
}

export default IapMetrics;
