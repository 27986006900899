const permissionsByRole = {};

permissionsByRole.VIEWER = ['element.read'];
permissionsByRole.USER = [
  ...permissionsByRole.VIEWER,
  'element.create', // example
  'draftGame.create',
  'draftGame.update',
  'abTest.create',
  'abTest.update.withDeviceId',
  'abTest.status.update.withDeviceId',
  'game.video.create',
  'game.video.update',
];
permissionsByRole.MANAGER = [
  ...permissionsByRole.USER,
  'element.update', // example
  'draftGame.publish',
  'draftGame.delete',
  'game.status.update',
  'quality.update', // config/tests
  'abTest.update',
  'abTest.status.update',
  'game.email.update',
  'send.email',
  'user.list',
  'manage.account',
  'manage.studio',
  'aiTools',
  'PDF.create',
  'select.studio',
  'show.sql.query',
  'show.comment.modal',
  'config.update',
  'dev.page',
  'show.running.configs',
];
permissionsByRole.ADMIN = [
  ...permissionsByRole.MANAGER,
  'element.delete', // example
  'studio.create',
  'game.pitch',
  'show.running.tests',
  'test.disable',
];

export const userRoles = [
  { label: 'User', value: 'USER' },
  { label: 'Viewer', value: 'VIEWER' },
  { label: 'Manager', value: 'MANAGER' },
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Sdk', value: 'SDK' },
];

export default permissionsByRole;
