import { Button, InputGroup, Modal, Nav, Navbar } from 'react-bootstrap';
import SearchableDropdown from '../../../../Components/SearchableDropdown';
import { abTestCountries } from '../../../../constants/abTestCountries';
import Form from 'react-bootstrap/Form';
import { useQuery, useSetState } from '../../../../utils/customHooks';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gameAbTestByIdSelector } from '../../../../data/slices/abTests';
import { getGameById } from '../../../../data/slices/studio';
import moment from 'moment';
import CalendarIcon from '../../../../Components/Icons/CalendarIcon';
import { DateRange } from 'react-date-range';
import React, { useState } from 'react';
import AndroidIcon from '../../../../Components/Icons/AndroidIcon';
import AppleIcon from '../../../../Components/Icons/AppleIcon';
import SqlQueryModal from '../Metrics/SqlQueryModal';
import { abTestsApi, useGetGameNetworksQuery } from '../../../../data/api/abTestsApi';
import Loader from '../../../../Components/Loader';
import { useUserPermission } from '../../../../Components/permissions/UserPermissions';

const JsonMetricsFilters = ({ sqlQuery }) => {
  const SqlQueryPermission = useUserPermission('show.sql.query');
  const { gameId, testId } = useParams();
  const foundGame = useSelector(getGameById(gameId));

  const { data: networks, isFetching } = useGetGameNetworksQuery(foundGame.bundleId);

  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame?.bundleId,
        store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple',
      })(state),
      testId,
    ),
  );
  const query = useQuery();
  const history = useHistory();
  const country = query.get('country')?.split(',')?.filter(Boolean) || [];
  const network = query.get('network')?.split(',')?.filter(Boolean) || [];
  const idfa = query.get('idfa') || '';
  const build = query.get('build') || '';
  const os = query.get('os') || abTestSelected.os;
  const from = query.get('from') || abTestSelected.startTs.split('T')[0] || '';
  const to = query.get('to') || abTestSelected.endTs.split('T')[0] || '';
  const ads = query.get('ads') || 0;

  const [filter, setFilter] = useSetState({
    country,
    idfa,
    build,
    os,
    from,
    to,
    network,
    ads,
    hasChanged: false,
  });
  const handleChange = (name, value) => {
    setFilter({ hasChanged: true });
    setFilter({ [name]: value });
  };
  const handleApply = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key] || (typeof filter[key] === 'object' && !filter[key]?.length)) query.delete(key);
      else query.set(key, encodeURIComponent(String(filter[key])));
    });
    query.delete('hasChanged');

    setFilter({ hasChanged: false });
    history.push(history.location.pathname + '?' + query.toString());
  };

  const [show, setShow] = useState(false);
  const [state, setState] = useSetState({
    selectionRange: {},
  });
  const handleDateChange = (ranges) => {
    setState({ selectionRange: ranges.selection });
  };

  const handleSubmit = () => {
    handleChange('from', moment(state.selectionRange.startDate?.toDateString()).format('YYYY-MM-DD'));
    handleChange('to', moment(state.selectionRange.endDate?.toDateString()).format('YYYY-MM-DD'));
    setShow(false);
  };

  const handleOpen = () => {
    setState({
      selectionRange: {
        startDate: new Date(filter.from),
        endDate: new Date(filter.to),
        key: 'selection',
      },
    });
    setShow(true);
  };
  return (
    <div className="data-filter-container mb-3 position-relative">
      {SqlQueryPermission && sqlQuery && (
        <div className="position-absolute end-0 " style={{ zIndex: 2 }}>
          <SqlQueryModal data={sqlQuery} />
        </div>
      )}
      <Navbar className="p-0" bg="bg-transparent" expand="lg">
        <Navbar.Brand className="d-lg-none p-0" href="#home">
          Filters
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="align-items-center">
            <Nav.Item>
              <Form.Group className="input-group select-drop">
                <InputGroup>
                  <InputGroup.Text className={`cursor-pointer pe-0 ${abTestSelected.os ? '' : 'bg-white'}`}>
                    {filter.os === 'ios' ? <AppleIcon size={14} /> : <AndroidIcon size={14} />}
                  </InputGroup.Text>
                  <Form.Select
                    value={!abTestSelected.os ? filter.os : abTestSelected.os} // os: "" means multiple os,
                    disabled={abTestSelected.os}
                    className={`form-select ${abTestSelected.os ? '' : 'bg-white'}`}
                    onChange={(e) => handleChange('os', e.target.value)}
                  >
                    <option value="android">Android</option>
                    <option value="ios">Ios</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Nav.Item>
            <Nav className="m-1">
              <>
                <Form.Group onClick={handleOpen} className=" cursor-pointer bg-white rounded" controlId="startDate">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      className=" cursor-pointer bg-white"
                      aria-describedby=""
                      placeholder="Start date"
                      readOnly
                      style={{ maxWidth: 132 }}
                      value={moment(filter.from).format('MMM D') + ' - ' + moment(filter.to).format('MMM D')}
                    />
                    <InputGroup.Text className="cursor-pointer bg-white">
                      <CalendarIcon size={14} />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Modal centered dialogClassName="modal-md" show={show} onHide={() => setShow(false)}>
                  <Modal.Body>
                    <div className="d-flex justify-content-around w-100">
                      <p className="fs-7 m-0">Start date</p>
                      <p className="fs-7 m-0">End date</p>
                    </div>
                    {state.selectionRange?.startDate && (
                      <DateRange
                        className="w-100 bg-white"
                        ranges={[state.selectionRange]}
                        onChange={handleDateChange}
                        minDate={new Date(abTestSelected.startTs.split('T')[0])}
                        maxDate={new Date(abTestSelected.endTs.split('T')[0])}
                      />
                    )}
                  </Modal.Body>
                  <Modal.Footer className="d-flex">
                    <div className="ms-auto">
                      <Button type="button" variant="outline-light" onClick={() => setShow(false)} className=" ms-auto">
                        Cancel
                      </Button>
                      <Button type="button" variant="primary" className=" ms-3" onClick={handleSubmit}>
                        Apply
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            </Nav>
            <Nav.Item>
              <SearchableDropdown
                placeholder={'World Wide'}
                multiple
                collapseGroup
                selectAll
                selected={filter.country}
                options={abTestCountries}
                onSelect={(data) => handleChange('country', data)}
                disableSearch
              />
            </Nav.Item>
            <Nav.Item>
              <Form.Group className="input-group select-drop">
                <Form.Select
                  value={filter.idfa}
                  className="form-select bg-white"
                  onChange={(e) => handleChange('idfa', Number(e.target.value) || null)}
                >
                  <option defaultValue value={''}>
                    Has Idfa
                  </option>
                  <option value={1}>Yes</option>
                  <option value={2}>No</option>
                </Form.Select>
              </Form.Group>
            </Nav.Item>
            <Nav.Item>
              {isFetching ? (
                <div className="d-flex justify-content-center">
                  <Loader parentStyle="loader" size={24} color={'#3F96C7'} />
                </div>
              ) : networks?.length > 0 ? (
                <SearchableDropdown
                  placeholder={'All Networks'}
                  multiple
                  collapseGroup
                  selected={filter.network}
                  options={networks.map((el) => ({ label: el, value: el }))}
                  onSelect={(data) => handleChange('network', data)}
                  disableSearch
                />
              ) : (
                <Form.Control placeholder="No Networks" disabled={networks.length === 0} />
              )}
            </Nav.Item>
            <Nav.Item>
              <Form.Control
                style={{ maxWidth: 102 }}
                type="text"
                className="text-dark-300 cursor-pointer bg-white"
                aria-describedby=""
                placeholder="Min. Build"
                value={filter.build || ''}
                onChange={(e) => handleChange('build', e.target.value)}
              />
            </Nav.Item>
            <Nav.Item>
              <Form.Check
                id="ingame"
                type={'checkbox'}
                label={<span className="fs-7 text-dark-300">Extra Revenue Sources</span>}
                checked={filter.ads}
                onClick={() => handleChange('ads', filter.ads ? 0 : 1)}
              />
            </Nav.Item>
            <Nav.Item>
              <Button disabled={!filter.hasChanged} onClick={handleApply} size="sm" className="">
                Apply
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default JsonMetricsFilters;
