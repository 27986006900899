import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gameAbTestByIdSelector } from '../../../data/slices/abTests';
import GameInfoCard from '../../../Components/GameInfoCard';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import TestInfoModal from '../_Components/TestInfoModal';
import { useState } from 'react';
import { abTestsApi } from '../../../data/api/abTestsApi';
import { useQuery } from '../../../utils/customHooks';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ProgressProvider from '../../../Components/CircleBarProgressProvider';
import 'react-circular-progressbar/dist/styles.css';
import BlockIcon from '../../../Components/Icons/BlockIcon';
import { getGameById } from '../../../data/slices/studio';

function MetricsHeader() {
  const [modal, setModal] = useState();
  let { gameId, testId } = useParams();
  let foundGame = useSelector(getGameById(gameId));
  const abTestSelected = useSelector((state) =>
    gameAbTestByIdSelector(
      abTestsApi.endpoints.getGameAbtests.select({
        bundleId: foundGame?.bundleId,
        store: foundGame?.store === 'amazon' ? 'amazon' : 'google,apple',
      })(state),
      testId,
    ),
  );

  const query = useQuery();
  const country = query.get('country') || '';
  const idfa = query.get('idfa') || 0;
  const build = query.get('build') || '';
  const os = query.get('os') || 'android';
  const from = query.get('from') || abTestSelected?.startTs?.split('T')[0] || '';
  const to = query.get('to') || abTestSelected?.endTs?.split('T')[0] || '';
  const network = query.get('network') || '';
  const ads = query.get('ads') || 0;
  const outliers = Number(query.get('outliers')) || 0;

  const { data: metrics } = abTestsApi.endpoints.getGameAbTestJsonMetrics.useQueryState({
    abTestId: abTestSelected?.id,
    country,
    idfa,
    build,
    os,
    from,
    to,
    network,
    ads,
    outliers,
  });
  let max = 0;
  let certainty = 0;
  let topCohort;
  metrics?.summary?.forEach((el) => {
    if (el?.meta?.arpu_total?.diffFromBase > max && el.meta?.arpu_total?.confidence > 0.95) {
      max = el.meta.arpu_total.diffFromBase;
      topCohort = el.user_cohort;
      certainty = el.meta?.arpu_total?.confidence;
    }
  });
  certainty *= 100;
  return abTestSelected ? (
    <div className="row g-3 mb-3 mt-0 d-print-none" style={{ height: 'auto' }}>
      <div className="col-xl-4 col-md-6 col-12">
        <div className="d-inline-flex w-100 bg-white rounded align-items-start">
          <div className="overflow-hidden w-100">
            <GameInfoCard id={gameId} />
          </div>
          <div
            className="d-flex btn border-0 btn-outline-light btn-sm p-1 m-3 ms-0"
            onClick={(e) => {
              e.stopPropagation();
              setModal(true);
            }}
          >
            <InfoIcon />
          </div>
          {modal && <TestInfoModal data={abTestSelected} onClose={() => setModal(false)} />}
        </div>
      </div>
      {metrics?.summary && (
        <div className="col-xl-7 col-md-6 col-12 align-items-stretch d-flex">
          {max > 0 ? (
            <div className="alert alert-info d-flex align-items-center mb-0">
              <div className="fs-7 me-3 d-flex flex-column justify-content-center" style={{ width: 54 }}>
                <ProgressProvider valueStart={0} valueEnd={certainty}>
                  {(value) => (
                    <CircularProgressbarWithChildren value={value}>
                      <div className="fs-8 text-center">
                        <strong>{Math.round(certainty)}%</strong>
                        <p className="fs-8 m-0">Trust</p>
                      </div>
                    </CircularProgressbarWithChildren>
                  )}
                </ProgressProvider>
              </div>

              <p className="m-0">
                <b>{topCohort}</b> records better performance ({Number(max).toFixed(2)}%) than the base cohort
              </p>
            </div>
          ) : (
            <div className="alert bg-light-400 d-flex align-items-center mb-0 border">
              <div className="me-3">
                <BlockIcon size={24} />
              </div>
              No cohort has performed better than the base cohort.
            </div>
          )}
        </div>
      )}

      {/*<div className="col-xl-5 col-md-6 col-12">
        <CohortOptionsInfo abTest={abTestSelected} />
      </div>
      <div className=" col-xl-3 col-lg-6 col-md-9 col-12">
        <CohortParamsInfo abTest={abTestSelected} />
      </div>*/}
    </div>
  ) : null;
}

export default MetricsHeader;
